// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  display: block !important;
  font-family: Avenir, sans-serif;
  font-size: 16px;
}

.iti-flag {
  background-image: url("https://patw0929.github.io/react-intl-tel-input/img/flags.png") !important;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti-flag {
    background-image: url("https://patw0929.github.io/react-intl-tel-input/img/flags@2x.png") !important;
  }
}
.App {
  height: 100vh;
}
.App > div {
  height: inherit;
}
.App .error-message {
  position: fixed;
  background: #FEE4E2;
  padding: 12px 16px;
  border-radius: 4px;
  color: #912018;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  font-size: 14px;
}
.App .success-message {
  position: fixed;
  background: #D1FADF;
  padding: 12px 16px;
  border-radius: 4px;
  color: #05603A;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,yBAAA;EACA,+BAAA;EACA,eAAA;AACJ;;AAEA;EACI,iGAAA;AACJ;;AAEA;EACE;IACI,oGAAA;EACJ;AACF;AAEA;EACI,aAAA;AAAJ;AACI;EACI,eAAA;AACR;AACI;EACI,eAAA;EACA,mBAAA;EACA,kBAAA;EACA,kBAAA;EACA,cAAA;EACA,SAAA;EACA,SAAA;EACA,2BAAA;EACA,YAAA;EACA,eAAA;AACR;AACI;EACI,eAAA;EACA,mBAAA;EACA,kBAAA;EACA,kBAAA;EACA,cAAA;EACA,SAAA;EACA,SAAA;EACA,2BAAA;EACA,YAAA;EACA,eAAA;AACR","sourcesContent":["body {\n    margin: 0;\n    display: block !important;\n    font-family: Avenir, sans-serif;\n    font-size: 16px;\n  }\n\n.iti-flag {\n    background-image: url(\"https://patw0929.github.io/react-intl-tel-input/img/flags.png\") !important;\n}\n\n@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {\n  .iti-flag {\n      background-image: url(\"https://patw0929.github.io/react-intl-tel-input/img/flags@2x.png\") !important;\n    }\n}\n\n.App {\n    height: 100vh;\n    &>div {\n        height: inherit;\n    }\n    .error-message {\n        position: fixed;\n        background: #FEE4E2;\n        padding: 12px 16px;\n        border-radius: 4px;\n        color: #912018;\n        top: 30px;\n        left: 50%;\n        transform: translateX(-50%);\n        z-index: 999;\n        font-size: 14px;\n    }\n    .success-message {\n        position: fixed;\n        background: #D1FADF;\n        padding: 12px 16px;\n        border-radius: 4px;\n        color: #05603A;\n        top: 30px;\n        left: 50%;\n        transform: translateX(-50%);\n        z-index: 999;\n        font-size: 14px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
